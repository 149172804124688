/**
 * File library.js
 * 
 * Custom scripts.
 */

(function($) {

	// Global Variables

	var header = $('#masthead'),
		logoHeight = $('.site-logo').outerHeight(),
		menuButton = $('.menu-button');

	// Assign mobile classes

	function mobileOrDesktop() {
		if ( $(window).width() > 1199 ) {
			$('body').addClass('is-desktop');
			$('body').removeClass('is-mobile');
		} else {
			$('body').addClass('is-mobile');
			$('body').removeClass('is-desktop');
		}
	}
	
	// All-click objects

	function allClick() {
		var clickbox = $('.allclick');
		clickbox.each(function() {
			var $this = $(this);
			$this.css('cursor', 'pointer');
			$this.find('a').on('click', function(e) {
				e.preventDefault();
			});
			
			if ($this.find('a').attr('target') == '_blank') {
				$this.on('click', function(e) {
					window.open($('a', this).attr('href'));
				});
			} else {
				$this.on('click', function(e) {
					window.location.href = $('a', this).attr('href');
				});
			}
		});
	}

	// Smooth scrolling

	function smoothScroll() {
		$("a[href*='#']").not("[href='#'],[data-toggle]").on('click', function(e) {
			if (this.hash !== "") {
				e.preventDefault();

				var hash = this.hash;
				$('html, body').animate({
					scrollTop: $(hash).offset().top - logoHeight
				}, 1000);
			}
		});
	}

	// Code to run when the document is ready

	$( document ).ready(function() {

		allClick();
		smoothScroll();
		mobileOrDesktop();

		// Inhibit click on primary menu items

		$('#primary-menu').find('a[href="#"]').each(function() {
			$(this).on('click', function(e) {
				e.preventDefault();

				$('#primary-menu .menu-item > a').not(this).removeClass('bordered').next('.sub-menu').removeClass('open');

				if ( $(this).hasClass('bordered') ) {
					$(this).removeClass('bordered').next('.sub-menu').removeClass('open');
				} else {
					$(this).addClass('bordered').next('.sub-menu').addClass('open');
				}
			});
		});

		// Fit text to container

		if ( $(window).width() > 767 ) {
			$('.claim').fitText(1.35);
		}

		// Add animation (AOS) to various contents

		if ( ! $('body').hasClass('page-template-tutta-pagina') ) {
			$('.entry-content p, .extra-content p').each(function(i) {
				$(this).attr({
					'data-aos': 'fade-up',
					'data-aos-offset': 200,
					'data-aos-delay': (i + 1) * 100,
					'data-aos-duration': 800
				});
			});
		}

		// Owl Carousel calls

		function changeClass(slide) {
			setTimeout(function() {
				$('.owl-item').each(function() {
					if ($(this).index() === slide) {
						$(this).addClass('animated');
					} else {
						$(this).removeClass('animated');
					}
				});
			}, 250);
		}

		$('.header-slideshow .owl-carousel').owlCarousel({
			autoplay: true,
			loop: true,
			items: 1,
			lazyLoad: true,
			dots: false,
			animateOut: 'animate__fadeOut',
			nav: true,
			navText: [ '<i class="icon-left-chevron"></i>', '<i class="icon-right-chevron"></i>' ],
			navSpeed: 1500,
			onInitialized: function(event) {
				changeClass(event.item.index);
			},
			onChanged: function(event) {
				changeClass(event.item.index);
			}
		});

		$('.reasons-slideshow .owl-carousel').owlCarousel({
			loop: true,
			items: 1,
			dotsContainer: '.reason-dots',
			animateIn: 'animate__slideInLeft',
			animateOut: 'animate__fadeOutRight'
		});

		$('.reviews-slideshow .owl-carousel').owlCarousel({
			loop: true,
			items: 1,
			dots: false,
			nav: true,
			navText: [ '<span>&#10229;</span>', '<span>&#10230;</span>' ],
			navSpeed: 1500
		});

		$('#social .owl-carousel').owlCarousel({
			loop: true,
			items: 1,
			dots: false,
			nav: true,
			navText: [ '<span>&#10229;</span>', '<span>&#10230;</span>' ],
			navSpeed: 500,
			responsive: {
				992: {
					items: 2,
					margin: 30,
					autoWidth: true
				},
				1200: {
					items: 3,
					margin: 50,
					autoWidth: true
				}
			}
		});

		$('.services-slideshow .owl-carousel').owlCarousel({
			loop: true,
			items: 1,
			dots: false,
			animateOut: 'animate__fadeOut',
			nav: true,
			navText: [ '<span>&#10229;</span>', '<span>&#10230;</span>' ],
			navSpeed: 1500,
			margin: 1
		});

		$('.structures-slideshow .owl-carousel').owlCarousel({
			loop: true,
			items: 2,
			margin: 15,
			center: true,
			dots: false,
			responsive: {
				768: {
					margin: 60
				},
				992: {
					margin: 80
				}
			}
		});

		// Items filtering

		$('.item-filters .filter').on('click', function(event) {
			event.preventDefault();
			$('.item-filters .filter').removeClass('active');
			$(this).addClass('active');

			var mix = $(this).data('filter');
			var items = $('[data-mix]');

			if ( mix != "all" ) {
				items.hide();
				$('[data-mix*="' + mix + '"]').fadeIn(500);
			} else {
				items.fadeIn(500);
			}
		});

		// Adjust padding for page without header images
		
		if ( $('body').hasClass('no-header-images') ) {
			$('#page').css( 'padding-top', logoHeight );
		}

		// Change header images for tablet devices

		if ( $('body').hasClass('is-mobile') ) {
			$('.header-images img').each(function() {
				var img = $(this);
				
				// Horizontal
				if ( $(window).width() > 991 && $(window).width() < 1200 ) {
					var dsk = img.data('img-desktop');

					img.attr({
						src: dsk,
						"data-src": dsk
					});
				}

				// Vertical
				if ( $(window).width() > 767 && $(window).width() < 992 ) {
					var mob = img.data('img-mobile');
						
					img.attr({
						src: mob,
						"data-src": mob
					});
				}
			});

			$('.entry-image img').each(function() {
				var img = $(this);
				
				// Tablets
				if ( $(window).width() > 767 && $(window).width() < 1200 ) {
					var dsk = img.data('img-desktop');

					img.attr({
						src: dsk,
						"data-src": dsk
					});
				}
			});
		}

	});

	// Code to run when the entire page is ready

	$( window ).on( 'load', function() {

		/* Intro Animations - Begin */

		$('#page').addClass( 'init' );

		// Hamburger menu
		setTimeout(function() {
			menuButton.removeClass( 'hidden' );
		}, 1500);

		// Right header
		setTimeout(function() {
			$('#masthead .right').removeClass( 'hidden' );
		}, 1800);

		/* Intro Animations - End */

		/* GSAP Animations - Begin */

		// Menu
		var menu = gsap.timeline({
			paused: true
		});

		menu.to(
			'#masthead',
			{
				duration: 0.25,
				backgroundColor: 'transparent',
				ease: 'power2.inOut',
				stagger: 0.1,
				onReverseComplete: () => gsap.set(
					'#masthead',
					{
						clearProps: 'backgroundColor'
					}
				)
			}
		).to(
			'#menu-offcanvas',
			{
				duration: 1,
				scaleX: 1,
				ease: 'power2.inOut',
				stagger: 0.2
			}
		).to(
			'.menu-item',
			{
				duration: 0.5,
				opacity: 1,
				ease: 'power2.inOut',
				stagger: 0.1
			},
			'-=0.3'
		);

		// Extra content
		$('#extra .item .content').each(function() {
			var content = $(this),
				item = content.parent();

			gsap.set(
				content,
				{
					scaleX: 0,
					scaleY: 0
				}
			);

			item.on('mouseenter', function() {
				gsap.to(
					content,
					{
						duration: 1,
						scaleX: 1,
						scaleY: 1,
						ease: 'power2.out'
					}
				);
			}).on('mouseleave', function() {
				gsap.to(
					content,
					{
						duration: 1,
						scaleX: 0,
						scaleY: 0,
						ease: 'power1.in',
						overwrite: true
					}
				);
			});
		});

		/* GSAP Animations - End */

		// Hide/show menu off canvas

		menuButton.on('click', function(e) {
			e.preventDefault();
			
			$(this).toggleClass( 'collapsed' );

			if ( ! $(this).hasClass('collapsed') ) {
				menu.play();
			} else {
				menu.reverse();
			}

			$('body').toggleClass( 'open-menu' );
		});

		// Equal Heights
		$('.equalHeight').equalHeights();

	});

	// Code to run when the browser window has been resized

	$( window ).on( 'resize', function() {

		mobileOrDesktop();

		// Change header images for tablet devices

		if ( $('body').hasClass('is-mobile') ) {
			var mql = window.matchMedia( "(orientation: portrait)" );
			
			$('.header-images img').each(function() {
				var img = $(this),
					org = img.data('src'),
					dsk = img.data('img-desktop');

				if ( mql.matches ) {
					// Portrait orientation
					img.attr({
						src: org,
						"data-src": org
					});
				} else {
					// Landscape orientation
					img.attr({
						src: dsk,
						"data-src": dsk
					});
				}
			});
		}

	});

	// Code to run when the browser window scrolls

	var pageY = 0;
	var entry_visibility = entry_icon_visibility = structures_visibility = extra_visibility = extra_icon_visibility = reviews_visibility = false,
		reviews_image_visibility = social_visibility = map_visibility = map_image_visibility = services_visibility = false;

	var extra_covered_offset = [],
		extra_covered_visibility = [];

	$('#extra .covered').each(function(i) {
		extra_covered_offset.push( $('#extra .covered:eq(' + i + ')').offset().top + 250 );
		extra_covered_visibility.push(false);
	});

	$( window ).on( 'scroll', function() {

		pageY = window.pageYOffset;

		if ( $(this).scrollTop() > logoHeight ) {
			header.addClass( 'moved' );
		} else {
			header.removeClass( 'moved' );
		}

		// Animations on scroll

		if ( $('.entry-content').length > 0 ) {
			var entry_offset = $('.entry-content').offset().top - 500;

			if ( ! entry_visibility && pageY > entry_offset ) {
				entry_visibility = true;

				setTimeout(function() {
					$('.entry-content').removeClass('hidden');
				}, 100);
			}
		}

		if ( $('.entry-icon').length > 0 ) {
			var icon_offset = $('.entry-icon').offset().top - 300;
			
			if ( ! entry_icon_visibility && pageY > icon_offset ) {
				entry_icon_visibility = true;

				setTimeout(function() {
					$('.entry-icon .covered .cover').removeClass('hidden');
				}, 100);
			}
		}

		if ( $('#structures mark').length > 0 ) {
			var structures_offset = $('#structures mark').offset().top - 500;

			if ( ! structures_visibility && pageY > structures_offset ) {
				structures_visibility = true;

				setTimeout(function() {
					$('#structures mark').removeClass('hidden');
				}, 100);
			}
		}

		if ( $('.extra-content').length > 0 ) {
			var extra_offset = $('.extra-content').offset().top - 500;

			if ( ! extra_visibility && pageY > extra_offset ) {
				extra_visibility = true;

				setTimeout(function() {
					$('.extra-content').removeClass('hidden');
				}, 100);
			}
		}

		if ( $('.extra-icon').length > 0 ) {
			var icon_offset = $('.extra-icon').offset().top - 300;
			
			if ( ! extra_icon_visibility && pageY > icon_offset ) {
				extra_icon_visibility = true;

				setTimeout(function() {
					$('.extra-icon .covered .cover').removeClass('hidden');
				}, 100);
			}
		}

		if ( $('#extra').length > 0 ) {
			$('#extra .covered').each(function(i) {
				if ( pageY > extra_covered_offset[i] && ! extra_covered_visibility[i] ) {
					extra_covered_visibility[i] = true;

					setTimeout(function() {
						$('#extra .covered:eq(' + i + ') .cover').removeClass('hidden');
					}, 100);
				}
			});
		}

		if ( $('#extra.hotel mark').length > 0 ) {
			var extra_offset = $('#extra.hotel mark').offset().top - 500;

			if ( ! extra_visibility && pageY > extra_offset ) {
				extra_visibility = true;

				setTimeout(function() {
					$('#extra.hotel mark').removeClass('hidden');
				}, 100);
			}
		}

		if ( $('#services mark').length > 0 ) {
			var services_offset = $('#services mark').offset().top - 500;

			if ( ! services_visibility && pageY > services_offset ) {
				services_visibility = true;

				setTimeout(function() {
					$('#services mark').removeClass('hidden');
				}, 100);
			}
		}

		if ( $('#reviews .data').length > 0 ) {
			var reviews_offset = $('#reviews .data').offset().top - 500;

			if ( ! reviews_visibility && pageY > reviews_offset ) {
				reviews_visibility = true;

				setTimeout(function() {
					$('#reviews .data').removeClass('hidden');
				}, 100);
			}
		}

		if ( $('#reviews .image').length > 0 ) {
			var image_offset = $('#reviews .image').offset().top - 300;
			
			if ( ! reviews_image_visibility && pageY > image_offset ) {
				reviews_image_visibility = true;

				setTimeout(function() {
					$('#reviews .image .covered .cover').removeClass('hidden');
				}, 100);
			}
		}

		if ( $('#social mark').length > 0 ) {
			var social_offset = $('#social mark').offset().top - 500;

			if ( ! social_visibility && pageY > social_offset ) {
				social_visibility = true;

				setTimeout(function() {
					$('#social mark').removeClass('hidden');
				}, 100);
			}
		}

		if ( $('#map .text').length > 0 ) {
			var map_offset = $('#map .text').offset().top - 500;

			if ( ! map_visibility && pageY > map_offset ) {
				map_visibility = true;

				setTimeout(function() {
					$('#map .text').removeClass('hidden');
				}, 100);
			}
		}

		if ( $('#map').length > 0 ) {
			var image_offset = $('#map .covered').offset().top - 300;
			
			if ( ! map_image_visibility && pageY > image_offset ) {
				map_image_visibility = true;

				setTimeout(function() {
					$('#map .covered .cover').removeClass('hidden');
				}, 100);
			}
		}

	});

})(jQuery);

// Magic mouse
if ( document.body.classList.contains( 'is-desktop' ) ) {
	var options = {
		"cursorOuter": "circle-basic",
		"hoverEffect": "circle-move",
		"hoverItemMove": false,
		"defaultCursor": true,
		"outerWidth": 20,
		"outerHeight": 20
	};

	magicMouse(options);
}

setTimeout(function() {
	AOS.init({
		//startEvent: "load",
		//disable: "mobile",
		easing: "ease-in-out-sine",
		once: "true"
	});
}, 100);
